<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="petIcon" alt="pet"></ion-img>
      <h1>{{ pet.name }}</h1>
      <p>Add meg a kedvenced születésnapját és mutasd be a világnak!</p>
    </div>

    <form @submit.prevent="submit" novalidate>
      <div class="form-group">
        <ion-label for="date" class="control-label">Kedvenced születésnapja:</ion-label>
        <ion-input
          type="date"
          id="date"
          v-model="dateOfBirth"
          @ion-input="validations.date.$touch"
          name="date"
          inputmode="text"
          required
          max="2999-12-31"
          :class="{ 'is-invalid': validations.date.$error || hasServerValidationError('date_of_birth') }"
          class="form-control"
        />
        <ErrorMessage :field="validations.date" />
        <ServerValidation field="date_of_birth" :server-validation="serverValidation" />
      </div>
      <div class="form-group">
        <ion-label for="bio" class="control-label">Bemutatkozás:</ion-label>
        <ion-textarea
          rows="5"
          id="bio"
          v-model="validations.bio.$model"
          placeholder="Mesélj néhány szóban a kedvencedről: mit érdemes tudni róla, miben leli örömét és hogyan okoz örömet másoknak."
          name="bio"
          required
          :class="{ 'is-invalid': validations.bio.$error || hasServerValidationError('bio') }"
          class="form-control"
          autocapitalize="sentences"
        />
        <ErrorMessage :field="validations.bio" />
        <ServerValidation field="bio" :server-validation="serverValidation" />
      </div>

      <div class="form-wizard-actions">
        <ion-button
          type="submit"
          id="submit"
          expand="full"
          shape="round"
          :disabled="isLoading"
          strong="true"
          class="btn"
        >
          {{ isLoading ? 'Loading' : stepActionText }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, computed, ref } from 'vue'
import { IonInput, IonLabel, IonTextarea, IonButton, IonRippleEffect, IonImg } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required, maxValue, maxLength } from '@vuelidate/validators'

import { REGISTRATION_SUCCESS_ROUTE, REGISTRATION_ACTIVATION_ROUTE } from '@/constants/routes'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useNavigation from '@/composables/navigation'
import useFormControl from '@/composables/formControl'
import useFormWizard from '@/composables/formWizard'
import useAuth from '@/composables/auth'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'RegistrationPetAbout',
  components: {
    IonInput,
    IonLabel,
    IonTextarea,
    IonButton,
    IonRippleEffect,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { navigateTo } = useNavigation()
    const { setPet, getPet, REGISTRATION_STATE } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { nextStep, isLastStep } = useFormWizard()
    const { AUTH_STATE, isActiveAuthSession, activateAuthSession } = useAuth()

    const bio = ref('')
    const dateOfBirth = ref('')
    const isLoading = ref(false)

    const date = computed(() => (dateOfBirth.value.length ? new Date(dateOfBirth.value) : null))

    const rules = {
      bio: { required, maxLength: maxLength(400) },
      date: { date: maxValue(new Date()) },
    }

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        dateOfBirth.value = pet.date_of_birth || ''
        bio.value = pet.bio || ''
      }
    }

    setInitialState()

    const validations = useVuelidate(rules, {
      bio,
      date,
    })

    const stepActionText = computed(() => (isLastStep.value ? 'Befejezés' : 'Tovább'))
    const isDoxRegistration = computed(() => REGISTRATION_STATE.dox)
    const petIcon = computed(() => {
      return getPet.value.species.id === 1
        ? require(`@/assets/images/animated/dog-winking.gif`)
        : require(`@/assets/images/animated/cat-winking.gif`)
    })
    const userEmail = computed(() => AUTH_STATE.user && AUTH_STATE.user.email)

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        const petID = getPet.value.id
        const payload = {
          bio: bio.value,
          date_of_birth: dateOfBirth.value,
        }

        const { data } = await httpClient.patch('pets', petID, payload)
        setPet(data.data)

        if (isDoxRegistration.value) {
          nextStep()
        } else {
          if (!isActiveAuthSession.value) {
            await httpClient.save('users/password-reset', {
              email: userEmail.value,
              reset_url: `${process.env.VUE_APP_URL}/${REGISTRATION_ACTIVATION_ROUTE}`,
            })
          }

          await activateAuthSession()
          await navigateTo({ name: REGISTRATION_SUCCESS_ROUTE })
        }

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      submit,
      pet: getPet,
      stepActionText,
      petIcon,
      validations,
      isLoading,
      serverValidation,
      hasServerValidationError,
      dateOfBirth,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 70px;
  }
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
