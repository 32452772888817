<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="require('@/assets/images/icons/health-book.svg')" alt="pet-vaccination-book"></ion-img>
      <h1>Egészségügyi könyv</h1>
      <p>
        Feltöltheted a kedvenced Egészségügyi könyvét a DOX-ba, így bármikor elérheted ha szükséged van rá. Lehetőség
        szerint személyes adatot ne tartalmazzon.
      </p>
    </div>

    <PhotoUpload v-model="file" />

    <small class="form-wizard-step-msg ion-text-center"
      >Az egészségügyi könyv kizárólag a DOX leolvasásakor látható</small
    >

    <form @submit.prevent="submit">
      <div class="form-wizard-actions">
        <ion-button type="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn new-btn">
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button
          @click.prevent="nextStep"
          type="button"
          fill="clear"
          expand="full"
          shape="round"
          strong="true"
          class="btn btn-link"
        >
          Kihagyom
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, reactive, toRefs } from 'vue'
import { IonButton, IonRippleEffect, IonImg } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'

import PhotoUpload from '@/components/Global/PhotoUpload'

export default defineComponent({
  name: 'RegistrationPetHealth',
  components: {
    IonButton,
    IonRippleEffect,
    PhotoUpload,
    IonImg,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { nextStep } = useFormWizard()

    const state = reactive({
      file: null,
      isLoading: false,
    })

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet) && pet.documents) {
        state.file = pet.documents.vaccination_book ? pet.documents.vaccination_book : null
      }
    }

    setInitialState()

    const submit = async () => {
      if (!state.file || state.isLoading) return

      try {
        state.isLoading = true

        const payload = {
          documents: {
            vaccination_book: state.file,
          },
        }

        const { data } = await httpClient.patch('pets', getPet.value.id, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    return {
      ...toRefs(state),
      submit,
      nextStep,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 65px;
  }
}

.form-wizard-step-msg {
  display: block;
  font-size: 10px;
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
