<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="require('@/assets/images/icons/vaccination.svg')" alt="pet-vaccination"></ion-img>
      <h1>Utolsó oltás</h1>
      <p>Add meg az utolsó veszettség elleni oltásának dátumát és értesítést küldünk a következő előtt.</p>
    </div>

    <form @submit.prevent="submit" novalidate>
      <div class="form-group">
        <ion-label for="date" class="control-label">Utolsó oltás dátuma:</ion-label>
        <ion-input
          type="date"
          id="date"
          v-model="validations.vaccinationDate.$model"
          name="date"
          min="1900-01-01"
          max="2999-12-31"
          inputmode="text"
          required
          :class="{
            'is-invalid': validations.vaccinationDate.$error || hasServerValidationError('last_vaccination_date'),
          }"
          class="form-control"
        />
        <ErrorMessage :field="validations.vaccinationDate" />
        <ServerValidation field="last_vaccination_date" :server-validation="serverValidation" />
      </div>

      <div class="form-wizard-actions">
        <ion-button type="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn new-btn">
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button
          @click.prevent="nextStep"
          type="button"
          fill="clear"
          expand="full"
          shape="round"
          strong="true"
          class="btn btn-link"
        >
          Kihagyom
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, ref } from 'vue'
import { IonButton, IonRippleEffect, IonInput, IonLabel, IonImg } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useFormControl from '@/composables/formControl'
import useFormWizard from '@/composables/formWizard'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'RegistrationPetHealth',
  components: {
    IonButton,
    IonRippleEffect,
    IonInput,
    IonLabel,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { nextStep } = useFormWizard()

    const vaccinationDate = ref('')
    const isLoading = ref(false)

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        vaccinationDate.value = pet.last_vaccination_date || ''
      }
    }

    setInitialState()

    const rules = {
      vaccinationDate: { required },
    }

    const validations = useVuelidate(rules, {
      vaccinationDate,
    })

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        const payload = {
          last_vaccination_date: vaccinationDate.value,
        }

        const { data } = await httpClient.patch('pets', getPet.value.id, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      submit,
      pet: getPet,
      validations,
      isLoading,
      serverValidation,
      hasServerValidationError,
      nextStep,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 80px;
  }
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
