<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <div class="pet-img">
        <ion-img
          :src="!isToggled ? require('@/assets/images/animated/dog.gif') : require('@/assets/images/dog.svg')"
          alt="pets"
          class="dog"
          :class="!isToggled ? 'gif' : 'svg'"
        />
      </div>
      <div class="pet-img">
        <ion-img
          :src="isToggled ? require('@/assets/images/animated/cat_v2.gif') : require('@/assets/images/cat_v2.svg')"
          alt="pets"
          class="cat"
          :class="isToggled ? 'gif' : 'svg'"
        />
      </div>
      <h1>Kedvenced</h1>
    </div>
    <form @submit.prevent="submit" novalidate>
      <div class="form-group ion-text-center">
        <ion-item class="form-group ion-pet-toggle">
          <ion-text :class="{ isToggled: isToggled }">{{
            findItemInCollectionById(speciesList, selectedSpecies).text
          }}</ion-text>
          <ion-toggle mode="ios" class="toggle-pet-select" v-model="isToggled" />
        </ion-item>
      </div>
      <div class="form-group">
        <ion-label for="pet-name" class="control-label"> Kedvenced neve: </ion-label>
        <ion-input
          id="pet-name"
          v-model="validations.name.$model"
          name="pet-name"
          inputmode="text"
          enterkeyhint="next"
          class="form-control"
          :class="{ 'is-invalid': validations.name.$error || hasServerValidationError('name') }"
          autocapitalize="sentences"
          required
        />
        <ErrorMessage :field="validations.name" />
        <ServerValidation field="name" :server-validation="serverValidation" />
      </div>
      <div class="form-group form-row">
        <span @click="showActionSheet(countries)" class="form-country-select" v-if="countries && countries.length">
          <ion-img
            :src="findItemInCollectionById(countries, selectedCountry).icon"
            :alt="findItemInCollectionById(countries, selectedCountry).text"
          />
        </span>
        <div class="form-col" v-if="cities">
          <ion-label for="city" class="control-label"> Lakhely: </ion-label>
          <Autocomplete
            v-model="validations.city.$model"
            @input="filterCities"
            @on-select="selectCity"
            :results="filteredCities"
            :error-state="validations.city.$error || hasServerValidationError('city')"
            placeholder="Budapest, IX. kerület"
          />
          <ErrorMessage :field="validations.city" />
          <ServerValidation field="city" :server-validation="serverValidation" />
        </div>
      </div>
      <div class="form-wizard-actions">
        <ion-button
          type="submit"
          id="submit"
          expand="full"
          shape="round"
          :disabled="isLoading"
          strong="true"
          class="btn"
        >
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, ref, reactive, toRefs, computed, onMounted, watch } from 'vue'
import {
  IonLabel,
  IonInput,
  IonButton,
  IonRippleEffect,
  actionSheetController,
  IonImg,
  IonItem,
  IonToggle,
  IonText,
} from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

import useFormControl from '@/composables/formControl'
import useRegistration from '@/composables/registration'
import useAuth from '@/composables/auth'
import usePet from '@/composables/pets'
import useFormWizard from '@/composables/formWizard'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'
import Autocomplete from '@/components/Global/Autocomplete'

export default defineComponent({
  name: 'RegistrationPetData',
  components: {
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    IonImg,
    IonItem,
    IonToggle,
    IonText,
    Autocomplete,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { getPet, setPet, REGISTRATION_STATE } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { AUTH_STATE } = useAuth()
    const { fetchPetOptions } = usePet()
    const { nextStep } = useFormWizard()

    const SPECIES = {
      DOG: 1,
      CAT: 2,
    }

    const state = reactive({
      isToggled: false,
      isLoading: false,
      cities: [],
      countries: [],
      filteredCities: [],
    })

    const name = ref('')
    const selectedCountry = ref(1)
    const city = ref({
      id: null,
      name: '',
    })
    const selectedSpecies = computed(() => (state.isToggled ? SPECIES.CAT : SPECIES.DOG))

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        state.isToggled = pet.species && pet.species.id === SPECIES.CAT
        name.value = pet.name || ''
        selectedCountry.value = pet.country ? pet.country.id : 1
        city.value = pet.city ? pet.city : null
      }
    }

    setInitialState()

    const rules = {
      name: { required },
      city: { required },
    }

    const validations = useVuelidate(rules, {
      name,
      city,
    })

    const speciesList = [
      {
        id: 1,
        text: ' Kutya',
        color: '#f64242',
      },
      {
        id: 2,
        text: 'Cica',
        color: '#000',
      },
    ]

    watch(selectedCountry, () => fetchCities())

    const speciesColor = computed(
      () => `background-color: ${helpers.findItemInCollectionById(speciesList, state.selectedSpecies).color}`,
    )
    const userId = computed(() => AUTH_STATE.user && AUTH_STATE.user.id)
    const RFID = computed(() => REGISTRATION_STATE.dox)

    const showActionSheet = async options => {
      const actionSheet = await actionSheetController.create({
        mode: 'ios',
        buttons: [
          ...options,
          {
            text: 'Mégse',
            role: 'cancel',
          },
        ],
        cssClass: 'styled-sheet',
      })

      return actionSheet.present()
    }

    async function fetchCities() {
      try {
        const { data } = await httpClient.get('cities', '', { country: selectedCountry.value })
        state.cities = data.data
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    async function fetchCountries() {
      try {
        const { data } = await httpClient.get('countries')
        state.countries = data.data

        state.countries = [...state.countries].map(item => {
          return {
            ...item,
            handler: () => (selectedCountry.value = item.id),
          }
        })

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    function filterCities(search) {
      state.filteredCities = [...state.cities]
        .filter(city => city.name.toLowerCase().includes(search.toLowerCase()))
        .map(city => city.name)
    }

    function selectCity(item) {
      city.value = [...state.cities].find(city => city.name.toLowerCase() === item.toLowerCase())
    }

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (state.isLoading) return

      try {
        serverValidation.value = {}
        state.isLoading = true

        let petData = null

        const payload = {
          name: name.value,
          species: {
            id: selectedSpecies.value,
            name: helpers.findItemInCollectionById(speciesList, selectedSpecies.value).text,
          },
          country: {
            id: selectedCountry.value,
            name: helpers.findItemInCollectionById(state.countries, selectedCountry.value).text,
          },
          city: city.value,
        }

        if (RFID.value) {
          Object.assign(payload, { rfid: RFID.value })
        }

        if (lodash.isEmpty(getPet.value)) {
          const { data } = await httpClient.save(`users/${userId.value}/pets`, payload)
          petData = data.data
        } else {
          const petID = getPet.value.id
          const { data } = await httpClient.patch('pets', petID, payload)
          petData = data.data
        }
        await fetchPetOptions(selectedSpecies.value)

        setPet(petData)
        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    onMounted(() => {
      !state.cities.length && fetchCities()
      !state.countries.length && fetchCountries()
    })

    return {
      ...toRefs(state),
      submit,
      speciesList,
      showActionSheet,
      speciesColor,
      findItemInCollectionById: helpers.findItemInCollectionById,
      filterCities,
      selectCity,
      selectedCountry,
      validations,
      serverValidation,
      hasServerValidationError,
      selectedSpecies,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.indicator {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border-radius: 50%;
  transition: 0.1s ease-out;
}

.ion-pet-toggle {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  --min-height: 30px;
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-border-width: 0;
  --border-color: #dfdfdf;

  ion-text {
    position: absolute;
    left: 30px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;

    &.isToggled {
      left: 26px;
    }
  }
}

.form-country-select {
  margin: 20px 15px 0 0;
}

.form-wizard-step-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .img {
    width: 76px;
    height: 76px;
    object-fit: cover;
  }

  .pet-img {
    display: flex;
    align-items: flex-end;
    width: 80px;
    height: 80px;
    overflow: hidden;

    ion-img {
      &.cat {
        width: 85px;
        height: 85px;
      }

      &.dog {
        width: 76px;
        height: 76px;
      }
    }
  }

  h1 {
    flex: 0 0 100%;
    min-width: 100%;
  }
}
</style>
