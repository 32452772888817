<template>
  <div class="animation">
    <ion-img class="animation__start" :src="animationStart" alt="dox-logo" />
    <ion-img class="animation__loop" :src="animationLoop" alt="dox-logo" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg } from '@ionic/vue'

export default defineComponent({
  components: {
    IonImg,
  },
  props: {
    animationStart: {
      type: String,
      required: true,
    },
    animationLoop: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.animation {
  width: 400px;
  height: 400px;
  position: relative;

  &.animated {
    .animation__start {
      opacity: 0;
    }
  }

  &:not(.animated) {
    .animation__loop {
      opacity: 0;
    }
  }

  &__start,
  &__loop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__start {
    z-index: 1;
  }

  &__loop {
    z-index: 0;
  }
}
</style>
