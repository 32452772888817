<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="petIcon" alt="pet"></ion-img>
      <h1>{{ pet.name }}</h1>
    </div>

    <form @submit.prevent="submit" novalidate v-if="options">
      <div class="form-group">
        <ion-label class="control-label">Egészségügyi állapot:</ion-label>
        <ion-select
          v-model="validations.health.$model"
          :compare-with="compareMultiple"
          interface="alert"
          :interface-options="{ cssClass: 'styled-sheet custom-alert' }"
          required
          multiple
          cancel-text="Mégse"
          placeholder="Válassz az alábbi lehetőségek közül"
          :class="{ 'is-invalid': validations.health.$error || hasServerValidationError('status') }"
          class="form-control"
        >
          <ion-select-option v-for="item in options.healthStatuses" :key="item.id" :value="item">{{
            item.name
          }}</ion-select-option>
        </ion-select>
        <ErrorMessage :field="validations.health" />
        <ServerValidation field="status" :server-validation="serverValidation" />
      </div>
      <div class="form-group">
        <ion-label class="control-label">Vércsoport:</ion-label>
        <ion-select
          mode="ios"
          v-model="validations.bloodType.$model"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válassz az alábbi lehetőségek közül"
          :class="{ 'is-invalid': validations.bloodType.$error || hasServerValidationError('blood_type') }"
          class="form-control"
        >
          <ion-select-option v-for="item in options.bloodTypes" :key="item.id" :value="item.id">{{
            item.name
          }}</ion-select-option>
        </ion-select>
        <ErrorMessage :field="validations.bloodType" />
        <ServerValidation field="blood_type" :server-validation="serverValidation" />
      </div>
      <div class="form-group">
        <ion-label class="control-label">Kedvenced ivartalanított?</ion-label>
        <ion-select
          mode="ios"
          v-model="validations.neutered.$model"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válassz az alábbi lehetőségek közül"
          :class="{ 'is-invalid': validations.neutered.$error || hasServerValidationError('neutered') }"
          class="form-control"
        >
          <ion-select-option :value="true">Igen</ion-select-option>
          <ion-select-option :value="false">Nem</ion-select-option>
        </ion-select>
        <ErrorMessage :field="validations.neutered" />
        <ServerValidation field="neutered" :server-validation="serverValidation" />
      </div>

      <div class="form-wizard-actions">
        <ion-button type="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn new-btn">
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button
          @click.prevent="nextStep"
          type="button"
          fill="clear"
          expand="full"
          shape="round"
          strong="true"
          class="btn btn-link"
        >
          Kihagyom
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, ref, computed, onMounted } from 'vue'
import { IonButton, IonRippleEffect, IonLabel, IonSelect, IonSelectOption, IonImg } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

import useRegistration from '@/composables/registration'
import useFormControl from '@/composables/formControl'
import usePet from '@/composables/pets'
import useFormWizard from '@/composables/formWizard'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'RegistrationPetHealth',
  components: {
    IonButton,
    IonRippleEffect,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { fetchPetOptions, PETS_STATE } = usePet()
    const { nextStep } = useFormWizard()

    const health = ref([])
    const neutered = ref('')
    const bloodType = ref('')
    const isLoading = ref(false)

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet) && pet.health) {
        health.value = pet.health.status ? pet.health.status : []
        bloodType.value = pet.health.blood_type ? pet.health.blood_type.id : ''
        neutered.value = pet.health.neutered ? pet.health.neutered : ''
      }
    }

    setInitialState()

    const rules = {
      health: {
        required,
        only_healthy: value => {
          return value.find(item => item.name === 'Teljesen egészséges') ? value.length === 1 : value.length > 0
        },
      },
      neutered: { required },
      bloodType: { required },
    }

    const validations = useVuelidate(rules, {
      health,
      neutered,
      bloodType,
    })

    const petIcon = computed(() => {
      return getPet.value.species.id === 1
        ? require(`@/assets/images/animated/dog-winking.gif`)
        : require(`@/assets/images/animated/cat-winking.gif`)
    })
    const options = computed(() => PETS_STATE.options)

    const compareMultiple = (f1, f2) => {
      if (Array.isArray(f2)) {
        if (!f1 || !f1.id) {
          return false
        }

        return f2.find(val => val && val.id === f1.id)
      }
      return f1 && f2 ? f1.id === f2.id : f1 === f2
    }

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        const payload = {
          health: {
            status: health.value,
            blood_type: {
              id: bloodType.value,
              name: helpers.findItemInCollectionById(options.value.bloodTypes, bloodType.value).name,
            },
            neutered: neutered.value,
          },
        }

        const { data } = await httpClient.patch('pets', getPet.value.id, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => fetchPetOptions(getPet.value.species.id))

    return {
      submit,
      compareMultiple,
      pet: getPet,
      petIcon,
      validations,
      isLoading,
      serverValidation,
      hasServerValidationError,
      options,
      nextStep,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 70px;
  }
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
