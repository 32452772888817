<template>
  <ion-progress-bar :value="progress" class="progress" />
</template>

<script>
import { defineComponent } from 'vue'

import { IonProgressBar } from '@ionic/vue'

export default defineComponent({
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  components: {
    IonProgressBar,
  },
})
</script>

<style lang="scss" scoped>
.progress {
  --progress-background: linear-gradient(to right, #f5bbf2, #faa1a3);
  --background: #fff;
  bottom: auto;
  max-width: 600px;
  margin: 10px auto 0 auto;
  height: 5px;
  position: relative;

  &,
  &-buffer-bar {
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
</style>
