import { ref } from 'vue'

export default function useAnimations() {
  const animationState = ref(false)

  const swapAnimation = (el, delay) => {
    setTimeout(() => {
      document.querySelector(el).classList.add('animated')
      animationState.value = true
    }, delay)
  }

  const hasAnimated = () => animationState.value

  const waitForAnimation = (checkIt, checkFor, delay, callback) => {
    while (checkIt() !== checkFor) {
      setTimeout(() => waitForAnimation(checkIt, checkFor, delay, callback), delay)
      return
    }

    callback()
  }

  return {
    animationState,
    swapAnimation,
    hasAnimated,
    waitForAnimation,
  }
}
