<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="require('@/assets/images/icons/passport.svg')" alt="pet-passport"></ion-img>
      <h1>Útlevél</h1>
      <p>
        Ha rendelkezel a kedvencednek kiállított Útlevéllel és szeretnéd a DOX-ban tárolni, akkor feltöltheted egy
        fotóval. Ezek az adatok, csak a DOX leolvasásakor láthatók.
      </p>
    </div>

    <PhotoUpload v-model="file" />

    <form @submit.prevent="submit">
      <div class="form-wizard-actions">
        <ion-button type="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn new-btn">
          {{ isLoading ? 'Loading' : stepActionText }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button
          @click.prevent="submit"
          type="button"
          fill="clear"
          expand="full"
          shape="round"
          strong="true"
          class="btn btn-link"
        >
          Kihagyom
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { IonButton, IonRippleEffect, IonImg } from '@ionic/vue'

import { REGISTRATION_SUCCESS_ROUTE, REGISTRATION_ACTIVATION_ROUTE } from '@/constants/routes'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useNavigation from '@/composables/navigation'
import useFormWizard from '@/composables/formWizard'
import useAuth from '@/composables/auth'

import PhotoUpload from '@/components/Global/PhotoUpload'

export default defineComponent({
  name: 'RegistrationPetHealth',
  components: {
    IonButton,
    IonRippleEffect,
    PhotoUpload,
    IonImg,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { navigateTo } = useNavigation()
    const { isLastStep } = useFormWizard()
    const { AUTH_STATE, isActiveAuthSession, activateAuthSession } = useAuth()

    const state = reactive({
      file: null,
      isLoading: false,
    })

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet) && pet.documents) {
        state.file = pet.documents.passport ? pet.documents.passport : null
      }
    }

    setInitialState()

    const stepActionText = computed(() => (isLastStep.value ? 'Befejezés' : 'Tovább'))
    const userEmail = computed(() => AUTH_STATE.user && AUTH_STATE.user.email)

    const setFile = file => (state.file = file)

    const submit = async () => {
      if (state.isLoading) return

      try {
        state.isLoading = true

        const payload = {
          documents: {
            passport: state.file,
          },
        }

        if (state.file) {
          const { data } = await httpClient.patch('pets', getPet.value.id, payload)
          setPet(data.data)
        }

        if (!isActiveAuthSession.value) {
          await httpClient.save('users/password-reset', {
            email: userEmail.value,
            reset_url: `${process.env.VUE_APP_URL}/${REGISTRATION_ACTIVATION_ROUTE}`,
          })
        }

        await activateAuthSession()
        navigateTo({ name: REGISTRATION_SUCCESS_ROUTE })

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    return {
      ...toRefs(state),
      submit,
      setFile,
      stepActionText,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 65px;
  }
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
