<template>
  <div class="content ion-text-center">
    <h1 class="title">WOOF</h1>
    <p>Egy DOX-szal sokkal több lehet a DOXOCIAL is.</p>
    <Animation
      id="animate-dox-registration-success-nodox"
      :animation-start="require('@/assets/images/animated/dox-registration-success-nodox.gif')"
      :animation-loop="require('@/assets/images/animated/dox-registration-success-nodox-loop.gif')"
    />
    <div class="animated-icon">
      <ion-img
        :src="currentIcon.icon"
        :alt="currentIcon.label"
        :style="{
          height: `${currentIcon.height}px`,
          'margin-top': `${currentIcon.marginTop}px`,
        }"
      />
      <span>{{ currentIcon.label }}</span>
    </div>
    <ion-button
      @click.prevent="goToFeed"
      type="button"
      expand="full"
      shape="round"
      strong="true"
      class="btn btn-primary"
    >
      Tovább az alkalmazáshoz
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import { IonButton, IonRippleEffect, IonImg } from '@ionic/vue'

import useAnimations from '@/composables/animations'
import usePosts from '@/composables/posts'

import Animation from '@/components/Animations/Animation'

export default defineComponent({
  name: 'RegistrationSuccessNoDox',
  components: {
    IonButton,
    IonRippleEffect,
    Animation,
    IonImg,
  },
  setup() {
    const { swapAnimation, hasAnimated, waitForAnimation } = useAnimations()
    const { goToFeed } = usePosts()

    const icons = [
      {
        label: 'Vészhelyzet mód',
        icon: require('@/assets/images/icons/call-invert.svg'),
        height: '60',
        marginTop: '8',
      },
      {
        label: 'Helyadatok kezelése',
        icon: require('@/assets/images/icons/location.svg'),
        height: '65',
        marginTop: '-7',
      },
      {
        label: 'Születésnapok és ünnepek',
        icon: require('@/assets/images/icons/cake.svg'),
        height: '65',
        marginTop: '-7',
      },
      {
        label: 'Egészségügyi információk',
        icon: require('@/assets/images/icons/health.svg'),
        height: '70',
        marginTop: '-2',
      },
      {
        label: 'Microchip kiolvasás',
        icon: require('@/assets/images/icons/chip.svg'),
        height: '50',
        marginTop: '14',
      },
      {
        label: 'Egészgégügyi könyv tárolása',
        icon: require('@/assets/images/icons/health-book.svg'),
        height: '55',
        marginTop: '10',
      },
      {
        label: 'Törzskönyv tárolása',
        icon: require('@/assets/images/icons/pedigree.svg'),
        height: '55',
        marginTop: '10',
      },
      {
        label: 'Útlevél tárolása',
        icon: require('@/assets/images/icons/passport.svg'),
        height: '55',
        marginTop: '10',
      },
      {
        label: '',
        icon: require('@/assets/images/icons/dox.svg'),
        height: '81',
        marginTop: '-11',
      },
    ]

    const iconIndex = ref(0)

    const currentIcon = computed(() => icons[iconIndex.value])

    onMounted(() => {
      swapAnimation('#animate-dox-registration-success-nodox', 1000)

      waitForAnimation(hasAnimated, true, 500, () => {
        document.querySelector('.animated-icon').classList.add('start-loop')

        setInterval(() => {
          iconIndex.value++
          if (iconIndex.value > icons.length - 1) {
            iconIndex.value = 0
          }
        }, 2000)
      })
    })

    return {
      icons,
      currentIcon,
      goToFeed,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.content {
  margin-bottom: 0 !important;
}

.animated-icon {
  position: relative;
  transform: translateY(-240px);
  text-align: center;
  height: 0;

  &.start-loop {
    ion-img {
      animation: animated-icon 2s linear infinite;
    }

    span {
      animation: animated-label 2s linear infinite;
    }
  }

  &:not(.start-loop) {
    opacity: 0;
  }

  ion-img {
    width: 60px;
    height: 70px;
    margin: 0 auto;
    filter: brightness(0) invert(1);
    object-position: bottom;
  }

  span {
    font-weight: bold;
    font-family: var(--ion-poppins-font);
    position: absolute;
    top: 120px;
    width: 400px;
    left: calc(50% - 200px);
  }
}

@keyframes animated-icon {
  0%,
  100% {
    transform: scale(0);
  }

  7%,
  93% {
    transform: scale(1.2);
  }

  8%,
  49%,
  60%,
  92% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.2);
  }
}

@keyframes animated-label {
  0%,
  100% {
    opacity: 0;
  }

  10%,
  90% {
    opacity: 1;
  }
}
</style>
