<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="require('@/assets/images/icons/cat-dog.svg')" alt="pets"></ion-img>
      <h1>Kedvenced</h1>
      <p>Kérjük válaszd ki melyik kedvencedhez adnád hozzá a DOX-ot!</p>
    </div>

    <form @submit.prevent="submit">
      <ion-radio-group v-model="selectedPet" name="pet">
        <div v-for="pet in pets" :key="pet.id" class="form-group--radio" @click="selectedPet = pet.id">
          <ion-label class="control-label">
            <ion-avatar class="avatar">
              <ion-img :src="pet.avatar" :alt="pet.name"></ion-img>
            </ion-avatar>
            {{ pet.name }}
          </ion-label>
          <ion-radio :value="pet.id" />
        </div>
      </ion-radio-group>
      <ion-radio-group>
        <div class="form-group--radio" @click.prevent="addNewPet">
          <ion-label class="control-label">
            <ion-avatar class="avatar">
              <ion-img :src="require('@/assets/images/plus-filled.svg')" alt="Új kedvenc hozzáadása"></ion-img>
            </ion-avatar>
            Kedvenc hozzáadása
          </ion-label>
        </div>
      </ion-radio-group>

      <div class="form-wizard-actions">
        <ion-button
          type="submit"
          id="submit"
          expand="full"
          shape="round"
          :disabled="isLoading"
          strong="true"
          class="btn new-btn"
        >
          {{ isLoading ? 'Loading' : 'Párosítás' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
        <ion-button
          @click="logout"
          type="button"
          fill="clear"
          expand="full"
          shape="round"
          strong="true"
          class="btn btn-link"
        >
          Kilépés
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { IonButton, IonRippleEffect, IonLabel, IonAvatar, IonImg, IonRadioGroup, IonRadio } from '@ionic/vue'

import httpClient from '@/core/api/api.service'

import { DOX_PAIR_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'
import useRegistration from '@/composables/registration'
import useNavigation from '@/composables/navigation'
import useFormWizard from '@/composables/formWizard'
import useAuth from '@/composables/auth'

export default defineComponent({
  name: 'RegistrationPetAbout',
  components: {
    IonButton,
    IonRippleEffect,
    IonLabel,
    IonAvatar,
    IonImg,
    IonRadioGroup,
    IonRadio,
  },
  setup() {
    const { PETS_STATE } = usePets()
    const { REGISTRATION_STATE, setPet, alreadyRegisteredDoxSteps, doxSteps } = useRegistration()
    const { navigateTo } = useNavigation()
    const { nextStep, setSteps } = useFormWizard()
    const { logout } = useAuth()

    const selectedPet = ref(null)
    const isLoading = ref(false)

    const RFID = computed(() => REGISTRATION_STATE.dox)
    const pets = computed(() => PETS_STATE.pets)

    async function addNewPet() {
      setSteps(doxSteps)
      nextStep()
    }

    async function submit() {
      if (!selectedPet.value || isLoading.value) return

      try {
        isLoading.value = true
        await httpClient.patch('pets', selectedPet.value, { rfid: RFID.value })
        const pet = pets.value.find(pet => pet.id === selectedPet.value)

        if (pet.has_dox) {
          navigateTo({ name: DOX_PAIR_ROUTE })
        } else {
          setSteps(alreadyRegisteredDoxSteps)
          setPet(pet)
          nextStep()
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      pets,
      selectedPet,
      submit,
      addNewPet,
      isLoading,
      logout,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  margin-bottom: 30px;

  ion-img {
    max-width: 140px;
  }
}

.form-wizard-actions {
  margin-top: 20px;
}

.form-group--radio {
  .avatar {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    --background: #dfdfdf;
  }
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
