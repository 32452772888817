<template>
  <ion-page>
    <ion-content class="gradient-bg" fullscreen>
      <div class="container">
        <div class="content">
          <DoxRegistrationForm v-if="dox && dox.length" />
          <RegistrationForm v-else />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { IonPage, IonContent } from '@ionic/vue'

import RegistrationForm from '@/components/Registration/RegistrationForm.vue'
import DoxRegistrationForm from '@/components/Registration/DoxRegistrationForm.vue'

import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'
import useAuth from '@/composables/auth'

export default defineComponent({
  name: 'Registration',
  components: {
    IonPage,
    IonContent,
    RegistrationForm,
    DoxRegistrationForm,
  },
  setup() {
    const { REGISTRATION_STATE, steps, authenticatedSteps, doxSteps } = useRegistration()
    const { setSteps } = useFormWizard()
    const { isAuthenticated } = useAuth()

    const dox = computed(() => REGISTRATION_STATE.dox)

    const ionViewWillEnter = () =>
      setSteps(dox.value && dox.value.length ? doxSteps : isAuthenticated.value ? authenticatedSteps : steps)

    return {
      ionViewWillEnter,
      dox,
    }
  },
})
</script>

<style lang="scss" scoped>
.container,
.content {
  height: 100%;
}

.content {
  padding: 50px 20px 25px;
  margin: 0 auto !important;
}
</style>
