<template>
  <div class="form-wizard-step ion-text-center">
    <div class="form-wizard-step-heading">
      <ion-img :src="petIcon" alt="pet"></ion-img>
      <h1>{{ pet.name }}</h1>
      <p>Töltsd fel a kedvenced profilképét!</p>
    </div>

    <PhotoUpload
      v-model="validations.image.$model"
      :error="validations.image.$error || hasServerValidationError('avatar')"
    />
    <ErrorMessage :field="validations.image" />
    <ServerValidation field="avatar" :server-validation="serverValidation" />

    <div class="form-wizard-actions">
      <ion-button @click.prevent="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn">
        {{ isLoading ? 'Loading' : 'Tovább' }}
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, computed, ref } from 'vue'
import { IonButton, IonRippleEffect, IonImg } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'
import useFormControl from '@/composables/formControl'

import PhotoUpload from '@/components/Global/PhotoUpload'
import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'RegistrationPetProfile',
  components: {
    PhotoUpload,
    IonButton,
    IonRippleEffect,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { nextStep } = useFormWizard()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const image = ref(null)
    const isLoading = ref(false)

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        image.value = pet.avatar || null
      }
    }

    setInitialState()

    const rules = {
      image: { required },
    }

    const validations = useVuelidate(rules, {
      image,
    })

    const petIcon = computed(() => {
      return getPet.value.species.id === 1
        ? require(`@/assets/images/animated/dog-head.gif`)
        : require(`@/assets/images/animated/cat-head.gif`)
    })

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        const petID = getPet.value.id
        const payload = {
          avatar: image.value,
        }

        const { data } = await httpClient.patch('pets', petID, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      pet: getPet,
      submit,
      petIcon,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-wizard-step-heading {
  ion-img {
    max-width: 90px;
  }
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
