<template>
  <Animation
    id="animate-dox-half-animation"
    :animation-start="require('@/assets/images/animated/dox-half.gif')"
    :animation-loop="require('@/assets/images/animated/dox-half-loop.gif')"
  />
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import useAnimations from '@/composables/animations'

import Animation from '@/components/Animations/Animation'
import useFormWizard from '@/composables/formWizard'

export default defineComponent({
  name: 'HalfAnimation',
  components: {
    Animation,
  },
  setup() {
    const { swapAnimation, waitForAnimation, hasAnimated } = useAnimations()
    const { nextStep } = useFormWizard()

    onMounted(() => {
      swapAnimation('#animate-dox-half-animation', 2040)

      waitForAnimation(hasAnimated, true, 500, () => {
        setTimeout(() => {
          nextStep()
        }, 2000)
      })
    })
  },
})
</script>

<style lang="scss" scoped>
.animation {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
