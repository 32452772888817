<template>
  <div class="form-wizard-step ion-text-center">
    <div class="form-wizard-step-heading">
      <div class="photo-wrapper">
        <PetAvatar :image="petImg" />
        <div class="user-icon">
          <img :src="userIcon" />
        </div>
      </div>
      <div class="header-text-wrapper">
        <h1 class="header-text">Gazdi fotó</h1>
      </div>
    </div>
    <div class="upload-wrapper">
      <PhotoUpload
        :additionalClass="'upload-user-photo'"
        v-model="validations.userImage.$model"
        :error="validations.userImage.$error || hasServerValidationError('avatar')"
      />
      <ErrorMessage :field="validations.userImage" />
      <ServerValidation field="avatar" :server-validation="serverValidation" />
      <p class="upload-text">
        <span>
          Tölts fel gazdifotót magadról. A fotód a kedvenceid profilképe mellett jelenik meg ahogy fent is láthatod.
        </span>
      </p>
    </div>
    <div class="form-wizard-actions">
      <ion-button
        @click.prevent="submit"
        expand="full"
        shape="round"
        :disabled="isLoading"
        strong="true"
        class="btn new-btn"
      >
        {{ isLoading ? 'Loading' : 'Tovább' }}
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
      <ion-button
        @click.prevent="nextStep"
        type="button"
        fill="clear"
        expand="full"
        shape="round"
        strong="true"
        class="btn btn-link"
      >
        Kihagyom
      </ion-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import { IonButton, IonRippleEffect } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'
import useFormControl from '@/composables/formControl'

import PhotoUpload from '@/components/Global/PhotoUpload'
import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'
import PetAvatar from '@/components/Pet/PetAvatar.vue'

export default defineComponent({
  name: 'UserPhoto',
  components: {
    PhotoUpload,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
    PetAvatar,
  },
  setup() {
    const { getPet } = useRegistration() // , setUserPhoto
    const { nextStep } = useFormWizard()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const userIcon = computed(() => {
      return require('@/assets/images/icons/profile-user-red.svg')
    })
    const userImage = ref(null)
    const isLoading = ref(false)

    const pet = getPet.value
    const petImg = pet.avatar

    const state = reactive({
      petImg: '',
      userPhoto: null,
    })

    const rules = {
      userImage: { required },
    }

    const validations = useVuelidate(rules, {
      userImage,
    })

    const submit = async () => {
      validations.value.$touch()
      //   setUserPhoto(userImage)
      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true
        const payload = {
          avatar: userImage.value,
        }

        const {
          data: { data: data },
        } = await httpClient.patch('me', '', payload)

        state.userPhoto = data.avatar

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      pet: getPet,
      submit,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
      userIcon,
      //...toRefs(state),
      petImg,
      nextStep,
    }
  },
})
</script>

<style lang="scss" scoped>
.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}

.form-wizard-step {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
}

.photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pet-avatar {
    border: 5px solid #fff;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 30%);
    position: relative;
    overflow: hidden;
    width: 160px;
    height: 160px;
    min-width: 160px;
    min-height: 160px;
    @media only screen and (max-width: 600px) {
      width: 160px;
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 0px 40px rgb(0 0 0 / 30%);
      border-radius: 50%;
    }
  }

  .user-icon {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 51.5%;
    border-radius: 50%;
    top: 153px;
    animation: zoom-in-zoom-out 1s ease infinite;
    background-color: #fff;
    @media only screen and (max-width: 600px) {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 68%;
      top: 48%;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}

.form-wizard-step-heading {
  @media only screen and (max-width: 600px) {
    /* margin-bottom: 20px;
    position: relative;
    margin-left: auto;
    margin-right: auto;*/

    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: -1em;
  }
  .header-text-wrapper {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 31px;
    text-align: center;
    @media only screen and (max-width: 600px) {
      display: flex;
      justify-content: center;
    }
    .header-text {
      margin-top: 19px;
      letter-spacing: -1px;
      @media only screen and (max-width: 600px) {
        width: 138px;
        height: 22px;
        margin-top: 19px;
      }
    }
  }

  ion-img {
    max-width: 90px;
  }
}

.upload-text {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #222222;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 19px;
    //  margin-bottom: 45px;
    margin-bottom: 20px;
    display: flex;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #222222;
    letter-spacing: -0.9px;
    span {
      width: 80%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.validation-errors {
  @media only screen and (max-width: 600px) {
    position: relative;
    top: 10px;
  }
}

.form-wizard-actions {
  margin-top: 31px;
  @media only screen and (max-width: 600px) {
    /*width: 100%;
    position: relative;
    bottom: -3em;
    height: 100px;
    margin-bottom: 50px;*/
    width: 100%;
    position: relative;
    bottom: -20px;
    height: 100px;
    margin-bottom: 0;
    margin-top: 0;

    .form-wizard-actions ion-button {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  @media only screen and (max-height: 725px) {
    margin-bottom: 60px;
  }
}
</style>
<style lang="scss">
.upload-wrapper {
  position: relative;
  top: 4em;
  margin-bottom: 220px;
  @media only screen and (max-width: 600px) {
    /*  position: relative;
    top: 0;
    margin-bottom: 143px;*/
    position: relative;
    top: -2em;
    margin-bottom: 0;
  }
  .upload-user-photo {
    width: 125px !important;
    height: 125px !important;
    margin: 40px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    position: relative;
    @media only screen and (max-width: 600px) {
      /* width: 140px !important;
      height: 140px !important;
      margin: 40px auto 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%);*/
      width: 120px !important;
      height: 120px !important;
      margin: 40px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }
    .upload-icon {
      width: 58.11px;
      height: 56px;
    }
  }
}
</style>
