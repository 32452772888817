<template>
  <ion-avatar
    :class="[`upload--${type}`, { 'upload--error': error || hasServerValidationError('file') }, additionalClass]"
    @click.prevent="getPhoto"
  >
    <ion-img
      v-if="!isLoading"
      class="upload"
      :class="{ 'upload-icon': !modelValue }"
      :src="imageSrc"
      alt="Kép feltöltés"
    />
    <span v-else>Feltöltés...</span>
  </ion-avatar>
  <ion-input
    type="hidden"
    name="image"
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <ServerValidation field="file" :server-validation="serverValidation" />
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { IonInput, IonAvatar, IonImg } from '@ionic/vue'

import usePhotoGallery from '@/composables/camera'
import useFormControl from '@/composables/formControl'

import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'PhotoUpload',
  components: {
    IonInput,
    IonAvatar,
    IonImg,
    ServerValidation,
  },
  emits: ['file', 'update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'rounded',
    },
    error: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    additionalClass: {
      type: [String],
      default: '',
    },
  },
  setup(props, { emit }) {
    const { takePhoto, isLoading } = usePhotoGallery()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const content = ref('')
    const imageSrc = computed(() => (props.modelValue ? props.modelValue : require('@/assets/images/icons/camera.svg')))

    const getPhoto = async event => {
      const img = event.currentTarget.querySelector('.upload')

      try {
        serverValidation.value = {}

        const photo = await takePhoto()

        img.src = photo.webviewPath
        img.classList.remove('upload')
        emit('update:modelValue', photo.url)
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      }
    }

    return {
      content,
      getPhoto,
      imageSrc,
      serverValidation,
      hasServerValidationError,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
.upload {
  position: relative;
  z-index: 2;

  &--square {
    --border-radius: 10px;

    &::before {
      border-radius: 10px;
    }
  }
}

.upload-icon {
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 0;
  z-index: 1;
}

ion-avatar {
  width: 180px;
  height: 180px;
  margin: 40px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    border-radius: 50%;
    border: 1px dashed #cccccc;
  }

  &.upload--error {
    &:before {
      border-width: 2px;
      border-color: #e45043;
    }
  }
}
</style>
