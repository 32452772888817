<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <ion-img :src="petIcon" alt="pet"></ion-img>
      <h1>{{ pet.name }}</h1>
    </div>
    <form @submit.prevent="submit" novalidate v-if="options">
      <div class="form-group">
        <ion-label class="control-label">Kedvenced fajtája:</ion-label>
        <ion-select
          mode="ios"
          v-model="validations.selectedBreed.$model"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válassz az alábbi lehetőségek közül"
          :class="{ 'is-invalid': validations.selectedBreed.$error || hasServerValidationError('breed') }"
          class="form-control"
        >
          <ion-select-option v-for="breed in options.breeds" :key="breed.id" :value="breed.id">{{
            breed.name
          }}</ion-select-option>
        </ion-select>
        <ErrorMessage :field="validations.selectedBreed" />
        <ServerValidation field="breed" :server-validation="serverValidation" />
      </div>

      <div class="form-group" v-if="selectedBreed && isMixBreed">
        <ion-label class="control-label">Szülők fajtái:</ion-label>
        <ion-select
          mode="ios"
          v-model="maleParentBreed"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válaszd ki a kan szülő fajtáját"
          :class="{ 'is-invalid': hasServerValidationError('parent_breeds') }"
          class="form-control"
        >
          <ion-select-option v-for="breed in options.breeds" :key="breed.id" :value="breed.id">{{
            breed.name
          }}</ion-select-option>
        </ion-select>
        <ion-select
          mode="ios"
          v-model="femaleParentBreed"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válaszd ki a szuka szülő fajtáját"
          :class="{ 'is-invalid': hasServerValidationError('parent_breeds') }"
          class="form-control"
        >
          <ion-select-option v-for="breed in options.breeds" :key="breed.id" :value="breed.id">{{
            breed.name
          }}</ion-select-option>
        </ion-select>
        <ServerValidation field="parent_breeds" :server-validation="serverValidation" />
      </div>

      <div class="form-group">
        <ion-label class="control-label">Kedvenced neme:</ion-label>
        <ion-select
          mode="ios"
          v-model="validations.sex.$model"
          required
          interface="action-sheet"
          :interface-options="{ cssClass: 'styled-sheet' }"
          cancel-text="Mégse"
          placeholder="Válassz az alábbi lehetőségek közül"
          :class="{ 'is-invalid': validations.sex.$error || hasServerValidationError('sex') }"
          class="form-control"
        >
          <ion-select-option value="Hím">Fiú</ion-select-option>
          <ion-select-option value="Nőstény">Lány</ion-select-option>
        </ion-select>
        <ErrorMessage :field="validations.sex" />
        <ServerValidation field="sex" :server-validation="serverValidation" />
      </div>

      <div class="form-wizard-actions">
        <ion-button
          type="submit"
          id="submit"
          expand="full"
          shape="round"
          :disabled="isLoading"
          strong="true"
          class="btn"
        >
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, reactive, toRefs, computed, ref } from 'vue'
import { IonSelect, IonLabel, IonSelectOption, IonButton, IonRippleEffect, IonImg } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

import useFormControl from '@/composables/formControl'
import useRegistration from '@/composables/registration'
import usePet from '@/composables/pets'
import useFormWizard from '@/composables/formWizard'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'RegistrationPetBreed',
  components: {
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonButton,
    IonRippleEffect,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { getPet, setPet } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { PETS_STATE } = usePet()
    const { nextStep } = useFormWizard()

    const state = reactive({
      maleParentBreed: null,
      femaleParentBreed: null,
      isLoading: false,
    })

    const sex = ref(null)
    const selectedBreed = ref(null)
    const options = computed(() => PETS_STATE.options)

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        sex.value = pet.sex || null
        selectedBreed.value = pet.breed ? pet.breed.id : null
        state.maleParentBreed = pet.parent_breeds && pet.parent_breeds.length ? pet.parent_breeds[0].id : null
        state.femaleParentBreed = pet.parent_breeds && pet.parent_breeds.length ? pet.parent_breeds[1].id : null
      }
    }

    setInitialState()

    const rules = {
      sex: { required },
      selectedBreed: { required },
    }

    const validations = useVuelidate(rules, {
      sex,
      selectedBreed,
    })

    const isMixBreed = computed(() => options.value.breeds.find(breed => breed.id === selectedBreed.value).is_mixed)

    const petIcon = computed(() => {
      return getPet.value.species.id === 1
        ? require(`@/assets/images/animated/dog-winking.gif`)
        : require(`@/assets/images/animated/cat-winking.gif`)
    })

    const parentBreeds = () => {
      if (!isMixBreed.value) {
        return []
      }

      return [
        {
          id: state.maleParentBreed,
          name: helpers.findItemInCollectionById(options.value.breeds, state.maleParentBreed).name,
          sex: 'Hím',
        },
        {
          id: state.femaleParentBreed,
          name: helpers.findItemInCollectionById(options.value.breeds, state.femaleParentBreed).name,
          sex: 'Nőstény',
        },
      ]
    }

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (state.isLoading) return

      try {
        serverValidation.value = {}
        state.isLoading = true

        const petID = getPet.value.id
        const payload = {
          sex: sex.value,
          is_mixed_breed: isMixBreed.value,
          breed: {
            id: selectedBreed.value,
            name: helpers.findItemInCollectionById(options.value.breeds, selectedBreed.value).name,
          },
          parent_breeds: parentBreeds(),
        }

        const { data } = await httpClient.patch('pets', petID, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    return {
      submit,
      pet: getPet,
      ...toRefs(state),
      isMixBreed,
      petIcon,
      validations,
      serverValidation,
      hasServerValidationError,
      options,
      selectedBreed,
    }
  },
})
</script>

<style lang="scss" scoped>
.form-control {
  margin-bottom: 10px;
}

.form-wizard-step-heading {
  ion-img {
    width: 76px;
    height: 76px;
  }
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
