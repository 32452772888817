<template>
  <ion-page>
    <ion-content class="gradient-bg" fullscreen>
      <div class="container">
        <div class="content ion-text-center">
          <ion-img :src="require('@/assets/images/icons/dox.svg')" alt="Dox logo" class="icon"></ion-img>
          <h1 class="title">WOOF</h1>
          <strong>Üdvözlünk a DOXOCIAL-ben!</strong>
          <p>
            A következő néhány lépésben a Kedcencedre formálhatod a DOX-od. Szánj rá időt és próbálj minél több
            információt megosztani róla. A segítségedre lehet a Törzskönyve, vagy az Egészségügyi könyve
          </p>
          <ion-button
            @click.prevent="navigateTo({ name: REGISTRATION_ROUTE })"
            type="button"
            color="primary"
            expand="full"
            shape="round"
            strong="true"
            class="btn btn-primary"
          >
            Regisztráció
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-button
            @click.prevent="navigateTo({ name: LOGIN_ROUTE, query: { redirect: REGISTRATION_ROUTE } })"
            type="button"
            fill="clear"
            expand="full"
            shape="round"
            strong="true"
            class="btn btn-link"
          >
            Már regisztráltál?
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonImg, IonButton, IonRippleEffect } from '@ionic/vue'

import { REGISTRATION_ROUTE, LOGIN_ROUTE } from '@/constants/routes'

import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'RegistrationWelcome',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonButton,
    IonRippleEffect,
  },
  setup() {
    const { navigateTo } = useNavigation()

    return {
      REGISTRATION_ROUTE,
      LOGIN_ROUTE,
      navigateTo,
    }
  },
})
</script>

<style scoped>
.content {
  padding: 65px 2.5rem 0;
}

.title {
  margin-bottom: 50px;
}

.icon {
  width: 75px;
  margin-left: auto;
  margin-right: auto;
}

.btn-primary {
  margin-top: 20px;
}

.btn-link {
  margin-top: 2rem;
}
</style>
