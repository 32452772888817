<template>
  <div class="form-wizard-step">
    <div class="form-wizard-step-heading ion-text-center">
      <Animation
        id="animate-dox-lost-animation"
        :animation-start="require('@/assets/images/animated/dox-lost.gif')"
        :animation-loop="require('@/assets/images/animated/dox-lost-loop.gif')"
      />
      <p>Ha eltűnne a kedvenced átállíthatod a DOX-ot vészhelyzet módba, így a megtalálója azonnal felhívhat téged!</p>
    </div>

    <form @submit.prevent="submit" novalidate>
      <div class="form-group">
        <ion-label for="tel" class="control-label"> Vészhelyzet esetén értesítendő telefonszám: </ion-label>
        <ion-input
          id="tel"
          type="tel"
          v-model="validations.phone.$model"
          name="tel"
          inputmode="text"
          placeholder="+36 30 123 4567"
          enterkeyhint="next"
          :class="{ 'is-invalid': validations.phone.$error || hasServerValidationError('phone') }"
          class="form-control"
          required
        />
        <ErrorMessage :field="validations.phone" />
        <ServerValidation field="phone" :server-validation="serverValidation" />
      </div>

      <div class="form-wizard-actions">
        <ion-button type="submit" expand="full" shape="round" :disabled="isLoading" strong="true" class="btn">
          {{ isLoading ? 'Loading' : 'Tovább' }}
          <ion-ripple-effect></ion-ripple-effect>
        </ion-button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, ref, onMounted } from 'vue'
import { IonButton, IonRippleEffect, IonLabel, IonInput } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useRegistration from '@/composables/registration'
import useFormControl from '@/composables/formControl'
import useFormWizard from '@/composables/formWizard'
import useAnimations from '@/composables/animations'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'
import Animation from '@/components/Animations/Animation'

export default defineComponent({
  name: 'RegistrationPetLost',
  components: {
    IonButton,
    IonRippleEffect,
    IonLabel,
    IonInput,
    ErrorMessage,
    ServerValidation,
    Animation,
  },
  setup() {
    const { setPet, getPet } = useRegistration()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { nextStep } = useFormWizard()

    const phone = ref('')
    const isLoading = ref(false)

    const { swapAnimation } = useAnimations()

    onMounted(() => {
      swapAnimation('#animate-dox-lost-animation', 1040)
    })

    function setInitialState() {
      const pet = getPet.value

      if (!lodash.isEmpty(pet)) {
        phone.value = pet.phone || ''
      }
    }

    setInitialState()

    const rules = {
      phone: { required },
    }

    const validations = useVuelidate(rules, {
      phone,
    })

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      isLoading.value = true

      try {
        serverValidation.value = {}

        const payload = {
          phone: phone.value,
        }

        const { data } = await httpClient.patch('pets', getPet.value.id, payload)
        setPet(data.data)

        nextStep()
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      submit,
      avatar: getPet.value.avatar,
      validations,
      isLoading,
      serverValidation,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
.animation {
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    margin-top: -50px;
    margin-bottom: -90px;
  }

  @media (min-width: 768px) {
    margin-bottom: -50px;
  }
}

.form-wizard-step-heading {
  p {
    position: relative;
    z-index: 1;
  }
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
