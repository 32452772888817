<template>
  <ion-page>
    <ion-content class="gradient-bg page-center" fullscreen>
      <div class="container">
        <RegistrationSuccessWasDox v-if="wasDox" />
        <RegistrationSuccessNoDox v-else />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent } from '@ionic/vue'

import useRegistration from '@/composables/registration'
import useFormWizard from '@/composables/formWizard'
import usePosts from '@/composables/posts'

import RegistrationSuccessWasDox from '@/components/Registration/RegistrationSuccessWasDox'
import RegistrationSuccessNoDox from '@/components/Registration/RegistrationSuccessNoDox'

export default defineComponent({
  name: 'RegistrationSuccess',
  components: {
    IonPage,
    IonContent,
    RegistrationSuccessWasDox,
    RegistrationSuccessNoDox,
  },
  setup() {
    const { clearCurrentDox, removePet, REGISTRATION_STATE } = useRegistration()
    const { resetFormWizard } = useFormWizard()
    const { goToFeed } = usePosts()

    const wasDox = ref(null)

    const ionViewWillLeave = () => {
      goToFeed()
    }

    onMounted(() => {
      wasDox.value = REGISTRATION_STATE.dox
      resetFormWizard()
      removePet()
      clearCurrentDox()
    })

    return {
      wasDox,
      ionViewWillLeave,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

p {
  margin: 0;
}

ion-button {
  margin-top: 35px;
}
</style>
