<template>
  <div class="dox-registration-form form-wizard">
    <ion-icon class="form-wizard-prev-step" :icon="backIcon" v-if="currentStepNumber > 0" @click="prevStep"></ion-icon>

    <div class="form-wizard-main">
      <component :is="currentStep" ref="currentStepComponent" />
    </div>

    <ion-footer class="form-wizard-footer">
      <ion-img :src="require('@/assets/images/logo.svg')" alt="logo" class="logo"></ion-img>
      <Progress :progress="currentProgress" />
    </ion-footer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonButton, IonFooter, IonImg, IonIcon } from '@ionic/vue'
import { arrowBackOutline } from 'ionicons/icons'

import useFormWizard from '@/composables/formWizard'

import Progress from '@/components/Global/Progress'
import UserData from '@/components/Registration/UserData'
import PetSelect from '@/components/Registration/PetSelect'
import PetData from '@/components/Registration/PetData'
import PetProfile from '@/components/Registration/PetProfile'
import PetAbout from '@/components/Registration/PetAbout'
import PetBreed from '@/components/Registration/PetBreed'
import PetLost from '@/components/Registration/PetLost'
import PetHealth from '@/components/Registration/PetHealth'
import PetChip from '@/components/Registration/PetChip'
import PetVaccinationDate from '@/components/Registration/PetVaccinationDate'
import PetVaccination from '@/components/Registration/PetVaccination'
import PetPedigree from '@/components/Registration/PetPedigree'
import PetPassport from '@/components/Registration/PetPassport'
import HalfAnimation from '@/components/Registration/HalfAnimation'
import UserPhoto from '@/components/Registration/PetOwnerPhoto'

export default defineComponent({
  name: 'DoxRegistrationForm',
  components: {
    IonButton,
    UserData,
    PetSelect,
    PetData,
    PetProfile,
    PetAbout,
    PetBreed,
    Progress,
    IonFooter,
    IonImg,
    IonIcon,
    PetLost,
    PetHealth,
    PetVaccination,
    PetVaccinationDate,
    PetPedigree,
    PetPassport,
    PetChip,
    HalfAnimation,
    UserPhoto,
  },
  setup() {
    const {
      currentStep,
      currentStepNumber,
      currentProgress,
      currentStepComponent,
      prevStep,
      nextStep,
      isLastStep,
      goToStep,
    } = useFormWizard()

    return {
      currentStep,
      currentStepNumber,
      currentProgress,
      currentStepComponent,
      prevStep,
      nextStep,
      goToStep,
      isLastStep,
      backIcon: arrowBackOutline,
    }
  },
})
</script>
