import { reactive, computed, toRefs } from 'vue'

const state = reactive({
  currentStepNumber: 0,
  currentStepComponent: null,
  steps: [],
})

export default function useFormWizard() {
  const setSteps = steps => (state.steps = steps)

  const stepsLength = computed(() => state.steps.length - 1)
  const isLastStep = computed(() => state.currentStepNumber === stepsLength.value)
  const inProgress = computed(() => state.currentStepNumber <= stepsLength.value)
  const currentStep = computed(() => state.steps[state.currentStepNumber])
  const currentProgress = computed(() => (state.currentStepNumber + 1) / state.steps.length)

  const prevStep = () => state.currentStepNumber--
  const nextStep = () => state.currentStepNumber++
  const goToStep = step => (state.currentStepNumber = step)

  const resetFormWizard = () => {
    state.currentStepNumber = 0
  }

  return {
    ...toRefs(state),
    isLastStep,
    currentProgress,
    inProgress,
    currentStep,
    prevStep,
    nextStep,
    goToStep,
    setSteps,
    resetFormWizard,
  }
}
