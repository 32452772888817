<template>
  <div class="registration-form form-wizard">
    <ion-icon class="form-wizard-prev-step" :icon="backIcon" v-if="currentStepNumber > 0" @click="prevStep"></ion-icon>
    <div class="form-wizard-main">
      <component :is="currentStep" ref="currentStepComponent" />
    </div>

    <ion-footer class="form-wizard-footer">
      <ion-img :src="require('@/assets/images/logo.svg')" alt="logo" class="logo"></ion-img>
      <ion-button @click="goToLogin" fill="clear" expand="full" shape="round" strong="true" class="btn btn-link">
        Már regisztráltam, belépek
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
      <Progress :progress="currentProgress" />
    </ion-footer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonButton, IonFooter, IonImg, IonIcon, IonRippleEffect } from '@ionic/vue'
import { arrowBackOutline } from 'ionicons/icons'

import useFormWizard from '@/composables/formWizard'
import { LOGIN_ROUTE } from '@/constants/routes'
import useAuth from '@/composables/auth'
import useNavigation from '@/composables/navigation'

import Progress from '@/components/Global/Progress'
import UserData from '@/components/Registration/UserData'
import PetData from '@/components/Registration/PetData'
import PetProfile from '@/components/Registration/PetProfile'
import PetAbout from '@/components/Registration/PetAbout'
import PetBreed from '@/components/Registration/PetBreed'

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    IonButton,
    UserData,
    PetData,
    PetProfile,
    PetAbout,
    PetBreed,
    Progress,
    IonFooter,
    IonImg,
    IonIcon,
    IonRippleEffect,
  },
  setup() {
    const {
      currentStep,
      currentStepNumber,
      currentProgress,
      currentStepComponent,
      prevStep,
      nextStep,
      isLastStep,
      goToStep,
    } = useFormWizard()
    const { isAuthenticated, logout } = useAuth()
    const { navigateTo } = useNavigation()

    function goToLogin() {
      if (isAuthenticated.value) {
        logout()
      } else {
        navigateTo({ name: LOGIN_ROUTE })
      }
    }

    return {
      currentStep,
      currentStepNumber,
      currentProgress,
      currentStepComponent,
      prevStep,
      nextStep,
      goToStep,
      isLastStep,
      backIcon: arrowBackOutline,
      goToLogin,
    }
  },
})
</script>
