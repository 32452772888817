<template>
  <ion-page>
    <ion-content class="gradient-bg page-center" fullscreen>
      <div class="container">
        <Animation
          id="animate-dox-pair-success"
          :animation-start="require('@/assets/images/animated/dox-pair-success.gif')"
          :animation-loop="require('@/assets/images/animated/dox-pair-success-loop.gif')"
        />
        <div class="content ion-text-center">
          <h1 class="title">Sikeres párosítás</h1>
          <p>Ezt a DOX-ot hozzárendeltük a kedvencedhez.</p>
          <ion-button
            @click.prevent="login"
            type="button"
            color="primary"
            expand="full"
            shape="round"
            strong="true"
            class="btn btn-primary"
          >
            Tovább az alkalmazáshoz
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </div>

        <ion-footer>
          <ion-img :src="require('@/assets/images/logo.png')" alt="logo" class="logo"></ion-img>
        </ion-footer>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { IonPage, IonContent, IonButton, IonRippleEffect, IonImg, IonFooter } from '@ionic/vue'

import useRegistration from '@/composables/registration'
import useAuth from '@/composables/auth'
import useAnimations from '@/composables/animations'
import usePosts from '@/composables/posts'

import Animation from '@/components/Animations/Animation'

export default defineComponent({
  name: 'DoxPair',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonRippleEffect,
    IonImg,
    IonFooter,
    Animation,
  },
  setup() {
    const { activateAuthSession } = useAuth()
    const { clearCurrentDox } = useRegistration()
    const { swapAnimation } = useAnimations()
    const { goToFeed } = usePosts()

    async function login() {
      await activateAuthSession()
      goToFeed()
    }

    const ionViewWillLeave = () => {
      login()
    }

    onMounted(() => clearCurrentDox())

    const ionViewDidEnter = () => swapAnimation('#animate-dox-pair-success', 2040)

    return {
      login,
      ionViewDidEnter,
      ionViewWillLeave,
    }
  },
})
</script>

<style lang="scss" scoped>
.animation {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
}

.content {
  margin-bottom: 0 !important;
  position: relative;
  z-index: 1;
  padding: 330px 20px 0;
  justify-content: flex-start;

  ion-button {
    margin-top: 10px;
  }
}

.dox-logo {
  max-width: 80px;
}

.title {
  margin-bottom: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

ion-footer {
  padding-bottom: 30px;
}
</style>
