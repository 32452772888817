<template>
  <ion-page>
    <ion-content class="gradient-bg page-center" fullscreen>
      <div class="container">
        <div class="content ion-text-center">
          <Animation
            id="animate-dox-reader"
            :animation-start="require('@/assets/images/animated/dox-reader.gif')"
            :animation-loop="require('@/assets/images/animated/dox-reader-loop.gif')"
          />
          <div class="overlap">
            <ion-img :src="require('@/assets/images/logo.png')" alt="logo" class="logo"></ion-img>
            <p>DOX leolvasása folyamatban...</p>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import { IonPage, IonContent, IonImg } from '@ionic/vue'
import { useRoute } from 'vue-router'

import { PET_ROUTE, REGISTRATION_WELCOME_ROUTE, REGISTRATION_ROUTE } from '@/constants/routes'

import httpClient from '@/core/api/api.service'

import useNavigation from '@/composables/navigation'
import useAuth from '@/composables/auth'
import useRegistration from '@/composables/registration'
import useAnimations from '@/composables/animations'

import Animation from '@/components/Animations/Animation'

import useGeolocation from '@/composables/geolocation'
import { MAPS_API } from '@/constants/types'
import { Loader } from '@googlemaps/js-api-loader'

export default defineComponent({
  name: 'DoxReader',
  components: {
    IonPage,
    IonContent,
    IonImg,
    Animation,
  },
  setup() {
    const route = useRoute()
    const { navigateTo } = useNavigation()
    const { isAuthenticated } = useAuth()
    const {
      setDox,
      setReadOs,
      getReadOs,
      setReadLatitude,
      getReadLatitude,
      setReadLongtitude,
      getReadLongtitude,
      setReadCity,
      getReadCity,
      setReadAddress,
      getReadAdress,
    } = useRegistration()
    const { swapAnimation, hasAnimated, waitForAnimation } = useAnimations()

    const hash = computed(() => route.params.hash)

    const { getCurrentPosition, askToTurnOnLocationServices, checkLocationServiceRequirements } = useGeolocation()

    async function turnonLocation() {
      await checkLocationServiceRequirements()
      await askToTurnOnLocationServices()
    }

    function getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null

      if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
      } else if (/Android/.test(userAgent)) {
        os = 'Android'
      } else if (/Windows Phone/.test(userAgent)) {
        os = 'WindowsPhone'
      } else {
        os = 'unknown'
      }

      return os
    }

    const saveReadLocation = async () => {
      try {
        const position = await getCurrentPosition()
        const currentPosition = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        reversePlaceSearchUrl(currentPosition.latitude, currentPosition.longitude)
        setReadLatitude(currentPosition.latitude)
        setReadLongtitude(currentPosition.longitude)
      } catch (error) {
        if (error.message == 'User denied Geolocation' || error.message == 'User denied geolocation prompt') {
          setReadLatitude(null)
          setReadLongtitude(null)
          setReadCity(null)
          setReadAddress(null)
        }
        console.log('position error: ', error)
      }
    }

    async function reversePlaceSearchUrl(lat, long) {
      const loader = new Loader({
        apiKey: MAPS_API,
        version: 'weekly',
      })

      await loader.load().then(() => {
        const geocoder = new window.google.maps.Geocoder()

        geocoder.geocode({ location: { lat: lat, lng: long } }).then(response => {
          if (response.results[0]) {
            setReadCity(response.results[0].address_components[2].long_name)
            setReadAddress(response.results[0].address_components[1].short_name)
          }
        })
      })
    }

    async function validateDoxTag() {
      setDox(hash.value)

      // lat & long. coords are changed to eachother 'cause PHP Point constructor new Point(lat, lng) changes to reverse in DB
      let params = {
        device_info: getReadOs.value,
        longitude: getReadLongtitude.value,
        latitude: getReadLatitude.value,
        city: getReadCity.value,
        address: getReadAdress.value,
      }

      try {
        const { data } = await httpClient.get('rfid', hash.value, params)
        const pet = data.data

        waitForAnimation(hasAnimated, true, 500, () => {
          navigateTo({ name: PET_ROUTE, params: { id: pet.id } })
          return Promise.resolve()
        })
      } catch (error) {
        waitForAnimation(hasAnimated, true, 500, () => {
          if (error.errStatus === 404) {
            if (isAuthenticated.value) {
              setTimeout(() => navigateTo({ name: REGISTRATION_ROUTE }), 1000)
            } else {
              setTimeout(() => navigateTo({ name: REGISTRATION_WELCOME_ROUTE }), 1000)
            }
          }

          return Promise.reject(error)
        })
      }
    }

    onBeforeMount(async () => {
      try {
        turnonLocation()
        await saveReadLocation()
        await setReadOs(getOS())
        setTimeout(() => {
          validateDoxTag()
        }, 2000)
      } catch {
        setTimeout(() => {
          validateDoxTag()
        }, 2000)
      }
    })

    const ionViewDidEnter = () => swapAnimation('#animate-dox-reader', 1000)

    return {
      validateDoxTag,
      ionViewDidEnter,
    }
  },
})
</script>

<style lang="scss" scoped>
.content {
  margin: 0 auto !important;
}

.logo {
  margin: 0 auto 10px;
}

#animate-dox-reader {
  position: absolute;
  margin-top: -50px;
}

.overlap {
  z-index: 1;
  position: relative;
  margin-top: 200px;
}
</style>
