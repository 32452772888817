<template>
  <Animation
    id="animate-dox-registration-success"
    :animation-start="require('@/assets/images/animated/dox-registration-success.gif')"
    :animation-loop="require('@/assets/images/animated/dox-registration-success-loop.gif')"
  />
  <div class="content ion-text-center">
    <h1 class="title">
      <ion-img :src="require('@/assets/images/logo.svg')" />
    </h1>
    <p>
      A DOX-od beállítottuk és készen áll egy életen át elkísérni társát! Helyezd föl kedvencedre és viseljétek büszkén!
    </p>
    <ion-button
      @click.prevent="goToFeed"
      type="button"
      expand="full"
      shape="round"
      strong="true"
      class="btn btn-primary"
    >
      Tovább az alkalmazáshoz
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { IonButton, IonRippleEffect, IonImg } from '@ionic/vue'

import useAnimations from '@/composables/animations'
import usePosts from '@/composables/posts'

import Animation from '@/components/Animations/Animation'

export default defineComponent({
  name: 'RegistrationSuccessWasDox',
  components: {
    IonButton,
    IonRippleEffect,
    IonImg,
    Animation,
  },
  setup() {
    const { swapAnimation } = useAnimations()
    const { goToFeed } = usePosts()

    onMounted(() => swapAnimation('#animate-dox-registration-success', 2040))

    return {
      goToFeed,
    }
  },
})
</script>

<style lang="scss" scoped>
.animation {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
}

.content {
  margin-bottom: 0 !important;
  position: relative;
  z-index: 1;
  padding-top: 330px;
  justify-content: flex-start;
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
